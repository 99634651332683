.pagination {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;

    &__box {
        border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
        width: 40px;
        height: 40px;
        margin: 0 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
    }

    &__box.active {
        background-color: #fad4ce;
        color: #fff;
    }
}