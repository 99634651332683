.invite {
    font-family: 'Montserrat', sans-serif;
    &__header {
        font-size: 40px;
        text-align: center;
        margin: 0 0 60px;
    }
    
    &__label {
        display: block;
        text-align: center;
        font-size: 30px;
        color: #555;
    }
    
    &__input {
        width: 90%;
        padding: 10px 20px;
        font-size: 20px;
        margin: 20px auto;
        box-sizing: border-box;
        display: block;
    }
    
    &__submit {
        width: 50%;
        margin: 33px auto;
        display: block;
        padding: 15px;
        font-size: 20px;
        background-color: #d6e3dc;
        color: #ffff;
        border-radius: 5px;
    }
    
    &__success-header {
        text-align: center;
        font-size: 50px;
    }
    
    &__success-subheader {
        text-align: center;
        padding: 10px;
        font-size: 20px;
    }

    &__error {
        text-align: center;
        a {
            font-size: 20px;
        }
    }
}