.footer {
    font-family: monospace;
    p {
        margin: 0;
        color: #272B2F;
        line-height: 1.5em;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
}