.forgot-password {
    &__message {
        width: 88%;
        margin: 0 auto;
        text-align: center;
    }

    &__message--success {
        height: 62vh;    
        padding: 60px 0 0;
        box-sizing: border-box;
    }
}