/* global styles  */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap');
@import "src/_colors.scss";

body {
    background-color:  #fff;
}

.container {
    max-width: 500px;
    margin: 0 auto;
    min-height: 70vh;
}

.add-post-button {
    width: 200px;
    height: 50px;
    text-align: center;
    display: block;
    margin: 20px auto;
    font-size: 40px;
    text-decoration: none;
    color: white;
    background-color: #1398d5;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

.input-error {
    border: solid 2px red!important;
    box-sizing: border-box;
}

.hidden {
    display: none;
}

.center-text {
    text-align: center;
}