.feed {
    &__go-back {
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
    }

    &__admin-button {
        margin: -20px auto 20px;
        display: block;
        width: 70px;
        border-radius: 50%;
    }
}