@import "src/_colors.scss";

.admin-menu {
  font-family: 'Montserrat', sans-serif;
  &__link {
    display: block;
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
  }
}

.approve-invites {
  font-family: 'Montserrat', sans-serif;
  &__table-hdr {
    font-weight: bold;
  }

  &__row {
    display: flex;
    margin: 20px 0;
    padding: 0 10px;
    span:first-child {
      width: 37%;
    }
    span:nth-child(2) {
      width: 33%;
    }
    span:nth-child(3) {
      margin-left: auto;
    }
  }

  &__approve-btn {
    color: #ffffff;
    background-color: #1398d5;
    padding: 8px;
    border-radius: 50%;
    position: relative;
    bottom: 6px;
  }

  &__no-pending {
    text-align: center;
    font-size: 20px;
  }
}

.admin-users {
  font-family: 'Montserrat', sans-serif;
  &__user-row {
    border: 2px solid #1398d5;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
  }

  &__user-row.wp_pass {
    border: 2px solid red;
  }

  &__user-name {
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-transform: capitalize;
  }

  &__user-info {
    display: flex;
  }

  &__user-email {
    width: 70%;
  }

  &__user-edit {
    margin-left: auto;
  }

  &__edit-input {
    display: block;
    width: 100%;
    height: 30px;
    font-size: 20px;
    margin: 10px 0;
    outline: none;
    border: solid 1px lightgray;
    padding: 0 15px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__edit-update {
    font-size: 2.25em;
    font-weight: 700;
    color: white;
    background-color: #089ad6;
    display: block;
    width: 90%;
    margin: 10px auto;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
  }

  &__edit-checkbox {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555;
    box-sizing: border-box;
    margin: 0 5px 0 0;
  }

  &__edit-checkbox:checked {
    background-color: #089ad6;
    border: 2px solid #089ad6;
  }
}

.user-events {
  font-family: 'Montserrat', sans-serif;

  &__event-row {
    display: flex;
    line-height: 0;
    border-bottom: 1px solid #000;
  }

  &__event-col {
    width: 50%;
  }

  &__event-col--header {
    font-weight: bold;
    cursor: pointer;
  }

  &__card {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 15px;
  }

  &__event {
    font-size: 25px;
    text-align: center;
  }

  &__property {
    font-size: 20px;
  }

  &__pagination {
    display: flex;
    margin: 20px 0;
    justify-content: space-evenly;
  }

  &__page-indicator {
    width: 18%;
    border: 1px solid black;
    text-align: center;
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
  }

  &__page-indicator--active {
    background-color: #000;
    color: #fff;
  }

  &__last-seen {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
}

.edit-post {
  font-family: 'Montserrat', sans-serif;
  &__row {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }
  &__image {
    height: 50px;
    object-fit: contain;
  }
  &__caption {
    width: 50%;
    margin-left: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1;
  }
  &__actions-cont {
    margin-left: auto;
    font-size: 20px;
    padding-right: 10px;
    .fa-edit {
      margin-right: 10px;
    }

    .fa-times-circle {
      color: red;
      margin: 0 0 0 10px;
    }
  }

  &__row--delete {
    display: block;
  }

  &__image--delete {
    height: 200px;
    margin: 0 auto;
    display: block;
  }

  &__caption--delete {
    margin: 10px auto;
    text-align: center;
    width: 75%;
  }

  &__delete {
    font-family: 'Montserrat', sans-serif;
  }

  &__delete-header {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }

  &__delete-actions {
    margin: 20px auto;
    display: flex;
  }

  &__btn {
    width: 50%;
    padding: 15px;
    color: #000;
    border-radius: 5px;
    border: none;
    margin: 0 5px;
    box-sizing: border-box;
  }

  &__cancel-delete-btn {
    background-color: $blue;
  }

  &__delete-btn {
    background-color: $darkPink;
  }

  &__textarea {
    margin: 15px 0 0;
    border-radius: 5px;
    border: solid 1px $gray;
  }
}
